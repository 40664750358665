import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import EventCard from 'ui/events/EventCard'
import ParkCard from 'ui/parks/ParkCard'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { DateRangePicker } from 'react-date-range';
import { addDays, format, startOfMonth, endOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import Announcements from '../events/Announcements'

calendarGlobal = []
intervalId = null

CalendarView = ({match}) ->
  feedItemId = match.params.id
  [calendar, setCalendar] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ]);
  [events, setEvents] = useState([])
  [reservations, setReservations] = useState([])
  [openAvailable, setOpenAvailable] = useState([])
  [eventsCurrentPage, setEventsCurrentPage] = useState(1)
  [openAvailableCurrentPage, setOpenAvailableCurrentPage] = useState(1)
  [eventsTotalPages, setEventsTotalPages] = useState(1)
  [openAvailableTotalPages, setOpenAvailableTotalPages] = useState(1)
  announcements = AnnouncementApi.kick('index')

  parseDate = (dateTime) =>
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar) =>
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate)
    }

  loadData = (calendar = null, page = 1) =>
    calendar = { startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) } unless calendar
    EventApi.filtered({ ...dateParams(calendar), page: page }).then (data) =>
      setEvents(data.events)
      setEventsTotalPages(data.totalPages || 1)
    ParkApi.openAvailable({ page: openAvailableCurrentPage }).then (data) =>
      setOpenAvailable(data.places)
      setOpenAvailableTotalPages(data.totalPages || 1)

  goToEventsPage = (page) =>
    if page >= 1 and page <= eventsTotalPages
      setEventsCurrentPage(page)
      loadData(calendarGlobal[0], page)

  goToOpenAvailablePage = (page) =>
    if page >= 1 and page <= openAvailableTotalPages
      setOpenAvailableCurrentPage(page)
      ParkApi.openAvailable({ page: page }).then (data) =>
        setOpenAvailable(data.places)

  updateDateRange = (range) =>
    setCalendar([range.selection])
    setEventsCurrentPage(1)
    loadData(range.selection)

  useEffect(() =>
    calendarGlobal = calendar
    loadData(calendarGlobal[0], eventsCurrentPage)
    return # Don't remove this line
  , [calendar, eventsCurrentPage])

  <TopLevelPage className="Calendar" modelId={feedItemId} base="/community-calendar">
    <section className="">
      <div className="row justify-content-center">
        <div className="col-md-8 separator">
          {
            if announcements
              <Announcements announcements={announcements} base="/community-calendar" />
          }
        </div>
      </div>
    </section>
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col text-center">
        <DateRangePicker
          onChange={updateDateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={calendar}
          direction="horizontal"
          inputRanges={[]}
        />
      </div>
    </div>
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3"> Community Events </h1>
        </div>
      </div>
      {
        if (events.length == 0)
          <NoResults>We’re sorry, this section contains no events.</NoResults>
        else
          <div className="container">
          <GradientedScroller containerClassName="feed-columns row">
            {
              for model in events
                <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                  <div className="feed-column-body">
                    {
                        if model instanceof Park
                          <ParkCard park={model} key={model.uid} base='/community-calendar' />
                        else
                          <EventCard event={model} key={model.id} base='/community-calendar' />
                    }
                  </div>
                </section>
            }
          </GradientedScroller>
          </div>
      }
    {### remove this section in 4.7 release feedback
      User.current && User.current.regular &&
      <section>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="section-heading my-3"> Current Reservations </h1>
          </div>
        </div>
        <div className="row">
          {
            if (reservations.length == 0)
              <NoResults>We’re sorry, this section contains no pages.</NoResults>
            else
              for model in reservations
                <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                  <ParkCard park={model} base='/community-calendar' />
                </div>
          }
        </div>
      </section>
    ###
    }
      <div className="row justify-content-center mt-4">
        <div className="col-auto">
          <nav aria-label="Pagination">
            <ul className="pagination">
              <li className={classNames('page-item', {disabled: eventsCurrentPage == 1})}>
                <button
                  className="page-link"
                  onClick={() => goToEventsPage(eventsCurrentPage - 1)}
                  disabled={eventsCurrentPage == 1}
                >
                  Previous
                </button>
              </li>
              {[...Array(eventsTotalPages)].map((_, index) => (
                <li className={classNames('page-item', {active: eventsCurrentPage == index + 1})} key={index}>
                  <button
                    className="page-link"
                    onClick={() => goToEventsPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className={classNames('page-item', {disabled: eventsCurrentPage == eventsTotalPages})}>
                <button
                  className="page-link"
                  onClick={() => goToEventsPage(eventsCurrentPage + 1)}
                  disabled={eventsCurrentPage == eventsTotalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
    <section>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="section-heading-lowercase my-3">Today's Open Availability </h1>
        </div>
      </div>
      <div className="row">
        { if (openAvailable.length == 0)
            <NoResults>All reservable amenities are currently reserved for today.</NoResults>
          else
            for model in openAvailable
              <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                <ParkCard park={model} base='/community-calendar'/>
              </div>
        }
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-auto">
          <nav aria-label="Pagination">
            <ul className="pagination">
              <li className={classNames('page-item', {disabled: openAvailableCurrentPage == 1})}>
                <button
                  className="page-link"
                  onClick={() => goToOpenAvailablePage(openAvailableCurrentPage - 1)}
                  disabled={openAvailableCurrentPage == 1}
                >
                  Previous
                </button>
              </li>
              {[...Array(openAvailableTotalPages)].map((_, index) => (
                <li className={classNames('page-item', {active: openAvailableCurrentPage == index + 1})} key={index}>
                  <button
                    className="page-link"
                    onClick={() => goToOpenAvailablePage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li className={classNames('page-item', {disabled: openAvailableCurrentPage == openAvailableTotalPages})}>
                <button
                  className="page-link"
                  onClick={() => goToOpenAvailablePage(openAvailableCurrentPage + 1)}
                  disabled={openAvailableCurrentPage == openAvailableTotalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  </TopLevelPage>

export default withErrorBoundary connected CalendarView